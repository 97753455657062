.container{
    margin: 0;
    display: flex;
}
.divBege{
    width: 33.33%;
    background-color: #A9790F;
}
.divPreta{
    width: 66.66%;
    background-color: black;
}
.h3{
    color:#fff;
    font-size: 48px;
    margin: 1% 40% 5% 0;
    line-height: 1;
}
.p{
    color:#fff;
    font-size: 24px;
    margin: 0;
}
.texto{
    margin: 0;
    padding: 5%  40% 5% 2%;
}

@media (max-width:1440px) {
    .container{
        margin: 0;
        display: flex;
    }
    .divBege{
        width: 33.33%;
        background-color: #A9790F;
    }
    .divPreta{
        width: 66.66%;
        background-color: black;
    }
    .h3{
        color:#fff;
        font-size: 36px;
        margin: 1% 35% 5% 0;
        line-height: 1;
    }
    .p{
        color:#fff;
        font-size: 20px;
        margin: 0;
    }
    .texto{
        margin: 0;
        padding: 5%  35% 5% 2%;
    }
}
@media (max-width:1024px) {
    .container{
        margin: 0;
        display: flex;
    }
    .divBege{
        width: 33.33%;
        background-color: #A9790F;
    }
    .divPreta{
        width: 66.66%;
        background-color: black;
    }
    .h3{
        color:#fff;
        font-size: 24px;
        margin: 1% 25% 5% 0;
        line-height: 1;
    }
    .p{
        color:#fff;
        font-size: 16px;
        margin: 0;
    }
    .texto{
        margin: 0;
        padding: 5%  25% 5% 2%;
    }
}
@media (max-width:768px) {
    .container{
        margin: 0;
        display: flex;
    }
    .divBege{
        width: 33.33%;
        background-color: #A9790F;
    }
    .divPreta{
        width: 66.66%;
        background-color: black;
    }
    .h3{
        color:#fff;
        font-size: 18px;
        margin: 1% 25% 5% 0;
        line-height: 1;
    }
    .p{
        color:#fff;
        font-size: 11px;
        margin: 0;
    }
    .texto{
        margin: 0;
        padding: 5%  25% 5% 2%;
    }
}
@media (max-width:425px) {
    .container{
        margin: 0;
        display: flex;
    }
    .divBege{
        width: 20%;
        background-color: #A9790F;
    }
    .divPreta{
        width: 80%;
        background-color: black;
    }
    .h3{
        color:#fff;
        font-size: 36px;
        margin: 1% 5% 5% 0;
        line-height: 1;
    }
    .p{
        color:#fff;
        font-size: 12px;
        margin: 0;
        line-height: 1.5;
    }
    .texto{
        margin: 0;
        padding: 5%  5% 5% 2%;
    }
}