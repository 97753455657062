.div{
    display: flex;
    text-align: center;
    justify-content: center;
    align-items:baseline;
    background-color: black;
    margin: 0;
    padding: 1% 0;
}
.p1{
    color: white;
    font-size: 24px;
    margin: 0;
}
.p2{
    padding-left: 1rem;
    color: white;
    font-size: 60px;
    margin: 0;
}

@media (max-width:1440px) {
    .p1{
        color: white;
        font-size: 20px;
        margin: 0;
    }
    .p2{
        padding-left: 1rem;
        color: white;
        font-size: 48px;
        margin: 0;
    }
}
@media (max-width:1024px) {
    .p1{
        color: white;
        font-size: 18px;
        margin: 0;
    }
    .p2{
        padding-left: 1rem;
        color: white;
        font-size: 36px;
        margin: 0;
    }
}
@media (max-width:768px) {
    .p1{
        color: white;
        font-size: 20px;
        margin: 0;
    }
    .p2{
        padding-left: .5rem;
        color: white;
        font-size: 36px;
        margin: 0;
    }
}