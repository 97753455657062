.footer{
  background-color: black;
  color: white;
  margin: 0;
}

.divZap{
  position: fixed;
  bottom: 3%;
  right: 3%;
  z-index: 800;
  animation: moviment 2s infinite linear;
}
.iconZap{
  width: 75px;
  height: auto;
}

@keyframes moviment {
  0%{
    bottom: 3%;
    right: 3%;
  }
  50%{
    bottom: 2.25%;
    right: 2.55%;
  }
  100%{
    bottom: 3%;
    right: 3%;
  }
}

.container{
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  padding: 2% 10%;
}
.img{
}
.logo{
  padding: 0 5%;
  margin: 0;
}
.nav{
  margin: 0;
  display: flex;
  justify-content: center;
}
.li{
  padding: 0 3%;
}
.contato{
  margin: 0;
  text-align: right;
  padding: 1% 5%;
}
.telefone{
  margin: 0;
}
.endereco{
  margin: 0;
}
.email{
  margin: 0;
}

.assinatura {
  display: flex;
  text-align: center;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  justify-content: center;
  margin: 0;
  background-color: #a9790f;
}

.assinaturaAutor {
  cursor: pointer;
  text-decoration: none;
  margin-left: 0.3rem;
  color: white;
  border-bottom: 1px white dashed;
}
@media (max-width:768px) {
  .container{
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2% 10%;
  }
  .logo{
    text-align: center;
  }
  .contato{
    margin: 0;
    text-align: center;
    padding: 1% 5%;
  }
}@media (max-width:425px) {
  .contato{
    padding-top: 3%;
    font-size: 12px;
  }
  .endereco{
    padding: 1% 10%;
  }
  .assinatura {
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 9px;
    font-weight: 400;
    justify-content: center;
    margin: 0;
    background-color: #a9790f;
  }
  
  .assinaturaAutor {
    cursor: pointer;
    text-decoration: none;
    margin-left: 0.3rem;
    color: white;
    border-bottom: 1px white dashed;
  }
  .iconZap{
    width: 50px;
    height: auto;
  }
}