.section {
  margin: 1% 20%;
  text-align: center;
  font-family: "Poppins";
}
.titulo {
  font-size: 24;
  font-weight: 500;
  margin: 2rem 0;
}
.container {
  display: flex;
  text-align: left;
  align-items: center;
}
.subtitulo {
  font-size: 48px;
  font-weight: 600;
  line-height: 1.2;
  margin: 1rem 0;
  font-family: "Montserrat";
}
.texto {
  font-size: 24px;
  border-bottom: #a9790f 2px solid;
  padding-bottom: 1rem;
  margin: 1% 0;
}
.texto1 {
  font-size: 24px;
  padding-bottom: 1rem;
  margin: 1% 0;
}
.orcamento {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  color: white;
}
.orcamentoBtn {
  text-align: center;
  background-color: #a9790f;
  padding: 0.5rem 0.5rem;
  border-radius: 15px;
  cursor: pointer;
  margin: 5% 30%;
  transition: .3s ease;
}
.orcamentoBtn:hover {
  text-align: center;
  background-color: black;
  padding: 0.5rem 0.5rem;
  border-radius: 15px;
  cursor: pointer;
  margin: 5% 30%;
}
.Video {
  width: 150%;
  height: auto;
}
.Source {
}
.divVideo {
  margin: 0 10%;
}
.boxAtendemos{
  display: flex;
  align-items: center;
}
.divAtendemos {
  text-align: center;
  text-align: center;
    border: 2px black solid;
    margin: 2% 0;
    padding: 0.5rem .5rem; 
}
.imagem{
  width: 25%;
  padding: 1rem 2rem;
}
.h2atendimento {
  font-size: 48px;
  font-weight: 700;
}

@media (max-width: 1440px) {
  .section {
    margin: 1% 20%;
  }
  .titulo {
    font-size: 20px;
    font-weight: 500;
    margin: 1rem 0;
  }
  .container {
    display: flex;
    text-align: left;
    align-items: center;
  }
  .subtitulo {
    font-size: 36px;
    font-weight: 700;
    line-height: 1.2;
    margin: 1rem 0;
  }
  .texto {
    font-size: 18px;
    border-bottom: #a9790f 2px solid;
    padding-bottom: 1rem;
    margin: 1% 0;
  }
  .orcamento {
    font-size: 24px;
    font-weight: 600;
    color: white;
  }
  .orcamentoBtn {
    color: white;
    background-color: #a9790f;
    padding: 0.5rem 0.5rem;
    border-radius: 15px;
    margin: 5% 15%;
    cursor: pointer;
  }
  .Video {
    width: 150%;
    height: auto;
  }
  .Source {
  }
  .divVideo {
    margin: 0 7%;
  }
  .divAtendemos {
    text-align: center;
  }
  .h2atendimento {
    font-size: 24px;
    font-weight: 700;
  }
}

@media (max-width: 1024px) {
  .section {
    margin: 1% 20%;
  }
  .titulo {
    font-size: 20px;
    font-weight: 500;
    margin: 1rem 0;
  }
  .container {
    display: flex;
    text-align: left;
    align-items: center;
  }
  .subtitulo {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2;
    margin: 1rem 0;
  }
  .texto {
    font-size: 16px;
    border-bottom: #a9790f 2px solid;
    padding-bottom: 1rem;
    margin: 1% 0;
  }
  .texto1 {
    font-size: 16px;
    padding-bottom: 1rem;
    margin: 1% 0;
  }
  .orcamento {
    font-size: 20px;
    font-weight: 600;
    color: white;
  }
  .orcamentoBtn {
    color: white;
    background-color: #a9790f;
    padding: 0.5rem 0.5rem;
    border-radius: 15px;
    margin: 5% 10%;
    cursor: pointer;
  }
  .Video {
    width: 120%;
    height: auto;
  }
  .Source {
  }
  .divVideo {
    margin: 0 7%;
  }
  .divAtendemos {
    text-align: center;
  }
  .h2atendimento {
    font-size: 18px;
    font-weight: 700;
  }
}

@media (max-width: 768px) {
  .section {
    margin: 1% 10%;
  }
  .titulo {
    font-size: 16px;
    font-weight: 500;
    margin: 1rem 0;
  }
  .container {
    display: flex;
    text-align: left;
    align-items: center;
  }
  .subtitulo {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.2;
    margin: 1rem 0;
  }
  .texto {
    font-size: 12px;
    border-bottom: #a9790f 2px solid;
    padding-bottom: 1rem;
    margin: 1% 0;
  }
  .texto1 {
    font-size: 12px;
    padding-bottom: 1rem;
    margin: 1% 0;
  }
  .orcamento {
    font-size: 18px;
    font-weight: 600;
    color: white;
  }
  .orcamentoBtn {
    color: white;
    background-color: #a9790f;
    padding: 0.5rem 0.5rem;
    border-radius: 15px;
    margin: 5% 10%;
    cursor: pointer;
  }
  .Video {
    width: 90%;
    height: auto;
  }
  .Source {
  }
  .divVideo {
    margin: 0 7%;
  }
  .divAtendemos {
    text-align: center;
  }
  .h2atendimento {
    font-size: 14px;
    font-weight: 700;
  }
}

@media (max-width: 602px) {
  .section {
    margin: 1% 5%;
  }
  .titulo {
    display: none;
  }
  .container {
    display: block;
    text-align: center;
    align-items: center;
  }
  .boxAtendemos{
    display: block;
  }
  .subtitulo {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2;
    margin: 2% 0;
  }
  .texto {
    font-size: 16px;
    border-bottom: #a9790f 2px solid;
    padding-bottom: 1rem;
    margin: 3% 0;
    text-align: center;
    line-height: 1.4;
  }
  .texto1 {
    font-size: 16px;
    padding-bottom: 1rem;
    margin: 3% 0;
    text-align: center;
    line-height: 1.4;
  }
  .orcamento {
    font-size: 20px;
    font-weight: 600;
    color: white;
    text-decoration: none;
  }
  .orcamentoBtn {
    color: white;
    background-color: #a9790f;
    padding: 0.5rem 0.5rem;
    border-radius: 15px;
    margin: 5% 10%;
    cursor: pointer;
  }
  .Video {
    padding: 2% 0;
    width: 90%;
    height: auto;
  }
  .Source {
  }
  .divVideo {
    margin: 0 7%;
  }
  .imagem{
    width: 75%;
    padding: 1rem 2rem;
  }
  .divAtendemos {
    text-align: center;
    border: 2px black solid;
    margin: 2% 5%;
    padding: 0.5rem 0.5rem;
  }
  .h2atendimento {
    font-size: 20px;
    font-weight: 700;
  }
}
