.img {
  max-width: 100%;
}
.containerBanner {
  position: relative;
}
.logo {
  display: none;
}
.apresentacao {
  box-sizing: border-box;
  position: absolute;
  top: 25%;
  padding: 0 20%;  
}
.h1Top {
  color: #fff;
  margin: 0;
  font-size: 60px;
}
.texto {
  color: #fff;
  font-size: 36px;
  font-weight: 500;
}
.container {
  display: flex;
  flex-wrap: nowrap;
}
.boxBtn {
  text-align: center;
  align-items: center;
}
.btn {
  color: black;
  text-decoration: none;
  background-color: white;
  padding: 0.357rem 0.5rem;
  border-radius: 15px;
  font-size: 36px;
  font-weight: 600;
  box-shadow: 1px 2px 1px 1px black;
  transition: 0.2s ease-out;
}
.btn:hover {
  color: #a9790f;
}
.h1Change {
  padding-left: 0.6rem;
}

@media (max-width: 1440px) {
  .apresentacao {
    position: absolute;
    top: 25%;
    padding: 0 20%;
  }
  .h1Top {
    color: #fff;
    margin: 0;
    font-size: 48px;
  }
  .texto {
    color: #fff;
    font-size: 24px;
    font-weight: 500;
  }
  .container {
    display: flex;
  }
  .boxBtn {
    text-align: center;
    align-items: center;
  }
  .btn {
    padding: 0.357rem 0.5rem;
    border-radius: 15px;
    font-size: 24px;
    font-weight: 600;
    box-shadow: 1px 2px 1px 1px black;
    transition: 0.2s ease-out;
  }
  .btn:hover {
    color: black;
  }
  .h1Change {
    padding-left: 0.6rem;
  }
}
@media (max-width: 1024px) {
  .apresentacao {
    position: absolute;
    top: 25%;
    padding: 0 15%;
  }
  .h1Top {
    color: #fff;
    margin: 0;
    font-size: 36px;
  }
  .texto {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
  }
  .container {
    display: flex;
  }
  .boxBtn {
    text-align: center;
    align-items: center;
  }
  .btn {
    padding: 0.357rem 0.5rem;
    border-radius: 15px;
    font-size: 18px;
    font-weight: 600;
    box-shadow: 1px 2px 1px 1px black;
    transition: 0.2s ease-out;
  }
  .btn:hover {
    color: black;
  }
  .h1Change {
    padding-left: 0.6rem;
  }
}
@media (max-width: 768px) {
  .apresentacao {
    position: absolute;
    top: 25%;
    padding: 0 10%;
  }
  .h1Top {
    color: #fff;
    margin: 0;
    font-size: 24px;
  }
  .texto {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
  }
  .container {
    display: flex;
  }
  .boxBtn {
    text-align: center;
    align-items: center;
  }
  .btn {
    padding: 0.3rem 0.4rem;
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    box-shadow: 1px 2px 1px 1px black;
    transition: 0.2s ease-out;
  }
  .btn:hover {
    color: black;
  }
  .h1Change {
    padding-left: 0.6rem;
  }
}
@media (max-width: 602px) {
  .apresentacao {
    display: none;
  }
  .logo {
    display: block;
    position: absolute;
    top: 40%;
    padding: 0 10%;
    width: 75%;
  }
}
