.textoUpper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 25%;
    text-align: center;
    background-color: #A9790F; /* Add a semi-transparent background to make text more readable */
    color: white; /* Text color */
  }

  
  .texto{
    font-size: 20px;
    line-height: 1.4;
    padding: 0 5%;
  }
  .nome{
    margin: 0;
  }

  @media (max-width:1440px) {
    .textoUpper {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 20%;
      text-align: center;
      background-color: #A9790F; /* Add a semi-transparent background to make text more readable */
      color: white; /* Text color */
    }
  
    
    .texto{
      font-size: 18px;
      line-height: 1.4;
      padding: 0 2.5%;
    }
    .nome{
      margin: 0;
    }
  }
  @media (max-width:1024px) {
    .textoUpper {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 5%;
      text-align: center;
      background-color: #A9790F; /* Add a semi-transparent background to make text more readable */
      color: white; /* Text color */
    }
  
    
    .texto{
      font-size: 16px;
      line-height: 1.2;
      padding: 0 2.5%;
    }
    .nome{
      margin: 0;
    }
  }