.section{    
    padding: 2% 10%;
    margin: 0;
    text-align: center;
}
.div{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    padding: 1% 2%;
}
.li{
    align-items: center;
    text-align: center;
    margin: 0;
    flex-basis: 40%;
    padding: 1%;
    flex-wrap: wrap;
}
.h4{
    font-size: 30px;
    margin: 0;
}
.img{
    margin: 0;
    width: 100%;
}
.p{
    margin: 0;
    font-size: 18px;
    font-weight: 600;
}
.boxOrcamento{
    margin-top: 5%;
}
.orcamento{
    text-decoration: none;
    color: white;
    background-color: #A9790F;
    padding: 1% 2%;
    border-radius: 20px;
    font-size: 20px;
    font-weight: 600;
    transition: .3s ease;
}
.orcamento:hover{
    color: black;
    background-color: #A9790F;
    padding: 1% 2%;
    border-radius: 20px;
    font-size: 20px;
    font-weight: 600;
    box-shadow: 0 0 5px 1px black;
}
@media (max-width:1440px) {
    .section{
        margin: 0;
        text-align: center;
    }
    .div{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .li{
        align-items: center;
        text-align: center;
        margin: 0;
        flex-basis: 40%;
        padding: 1%;
    }
    .h4{
        font-size: 20px;
        margin: 0;
    }
    .img{
        margin: 0;
        width: 100%;
    }
    .p{
        margin: 0;
        font-size: 14px;
        font-weight: 600;
    }
    .boxOrcamento{
        margin-top: 5%;
    }
    .orcamento{
        text-decoration: none;
        color: white;
        background-color: #A9790F;
        padding: 1% 2%;
        border-radius: 20px;
        font-size: 18px;
        font-weight: 600;
        transition: .3s ease;
    }
    .orcamento:hover{
        color: black;
        background-color: #A9790F;
        padding: 1% 2%;
        border-radius: 20px;
        font-size: 20px;
        font-weight: 600;
        box-shadow: 0 0 5px 1px black;
    }
}
@media (max-width:1024px) {
    .section{
        margin: 0;
        text-align: center;
    }
    .div{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .li{
        align-items: center;
        text-align: center;
        margin: 0;
        flex-basis: 35%;
        padding: 1%;
    }
    .h4{
        font-size: 18px;
        margin: 3% 0;
    }
    .img{
        margin: 0;
        width: 100%;
    }
    .p{
        margin: 0;
        font-size: 14px;
        font-weight: 600;
    }
    .boxOrcamento{
        margin-top: 5%;
    }
    .orcamento{
        text-decoration: none;
        color: white;
        background-color: #A9790F;
        padding: 1% 2%;
        border-radius: 20px;
        font-size: 14px;
        font-weight: 600;
        transition: .3s ease;
    }
    .orcamento:hover{
        color: black;
        background-color: #A9790F;
        padding: 1% 2%;
        border-radius: 20px;
        font-size: 20px;
        font-weight: 600;
        box-shadow: 0 0 5px 1px black;
    }
}

@media (max-width:768px) {
    .section{
        margin: 0;
        text-align: center;
    }
    .div{
        display: block;
        justify-content: center;
    }
    .li{
        align-items: center;
        text-align: center;
        margin: 5% 0;
        flex-basis: 35%;
        padding: 1%;
    }
    .h4{
        font-size: 24px;
        margin: 0;
    }
    .img{
        margin: 0;
        width: 80%;
    }
    .p{
        margin: 0;
        font-size: 16px;
        font-weight: 600;
        padding: 0 10%;
    }
    .boxOrcamento{
        margin: 5% 0;
    }
    .orcamento{
        text-decoration: none;
        color: white;
        background-color: black;
        padding: .8rem 1rem;
        border-radius: 25px;
        box-shadow: 1px 1px 2px 2px #A9790F;
        font-size: 20px;
        font-weight: 600;
        transition: .3s ease;
    }
    .orcamento:hover{
        color: black;
        background-color: #A9790F;
        padding: 1% 2%;
        border-radius: 20px;
        font-size: 20px;
        font-weight: 600;
        box-shadow: 0 0 5px 1px black;
    }
}