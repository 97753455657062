.carousel{
  margin: 3% 10%;
  text-align: center;
}

.image{
  width: 100%;
}
.h2{
  font-size: 36px;
}

@media (max-width: 768px) {
  .h2{
    font-size: 24px;
  }
}