.header {
  background-color: hsla(0, 0%, 0%, 0.3);
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 1000;
  border-bottom: 2px #a9790f solid;
  transition: 0.3 ease;
}
.btn{
  margin-right: 1rem;
}
.menu{
  display: none;
}

.hamburgerButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 30px;
  line-height: 1;
  padding: 0.5rem;
  transition: color 0.3s;
  color: #a9790f;
}
.hamburgerButton::after {
  background: black;
  color: #a9790f;
}

.mobileMenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: rgba(0,0,0,0.3);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 10px;
  transition: all 0.3s;
}

/* Classe para animar a abertura do menu */
.open {
  display: block;
  transform: scaleY(1);
  transform-origin: top;
  opacity: 1;
  
}

/* Estilo dos links no menu móvel */
.mobileMenu a {
  display: block;
  color: #fff;
  text-decoration: none;
  padding: 5px 0;
  transition: color 0.3s;
}

/* Efeito de cor no hover dos links do menu móvel */
.mobileMenu a:hover {
  color: #f7d400;
}

.header.Rolando{
  background-color: hsla(0, 0%, 0%, 1)
}
.divHome {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  padding: 0 1rem;
}
.divImage {
}

.img {
  max-width: 250px;
}
.nav {
  display: flex;
}
.botao {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  transition: .2s ease-in;
}
.botao:hover {
  color: #a9790f;
}
.caixaBtn {
}
.btn {
  cursor: pointer;
  text-decoration: none;
  background-color: #a9790f;
  border: #a9790f solid 2px;
  border-radius: 15px;
  padding: 0.375rem 0.5rem;
  color: white;
  font-size: 18px;
  white-space: nowrap;
  font-weight: 600;
  transition: .2s ease;
}
.btn:hover{
  color:black;
  box-shadow: 0 0 2px 3px #a9790f;
}
.li {
  font-size: 18px;
  font-weight: 600;
  padding: 0 1rem;
}


@media (max-width: 1440px) {  
  .btn {
    cursor: pointer;
    text-decoration: none;
    background-color: #a9790f;
    border: #a9790f solid 2px;
    border-radius: 15px;
    padding: 0.375rem 0.5rem;
    color: white;
    font-size: 16px;
    white-space: nowrap;
    font-weight: 600;
    transition: .2s ease;
  }
  .btn:hover{
    color:black;
    box-shadow: 0 0 2px 3px #a9790f;
  }
  .li {
    font-size: 16px;
    font-weight: 600;
    padding: 0 1rem;
  }
  .img {
    max-width: 220px;
  }
}
@media (max-width: 1024px) {  
  .btn {
    cursor: pointer;
    text-decoration: none;
    background-color: #a9790f;
    border: #a9790f solid 2px;
    border-radius: 15px;
    padding: 0.375rem 0.5rem;
    color: white;
    font-size: 14px;
    white-space: nowrap;
    font-weight: 600;
    transition: .2s ease;
  }
  .btn:hover{
    color:black;
    box-shadow: 0 0 2px 3px #a9790f;
  }
  .li {
    font-size: 14px;
    font-weight: 600;
    padding: 0 1rem;
  }
  .img {
    max-width: 200px;
  }
}
@media (max-width: 768px) {  
  .btn {
    cursor: pointer;
    text-decoration: none;
    background-color: #a9790f;
    border: #a9790f solid 2px;
    border-radius: 15px;
    padding: 0.1rem 0.3rem;
    color: white;
    font-size: 9px;
    white-space: nowrap;
    font-weight: 600;
    transition: .2s ease;
  }
  .btn:hover{
    color:black;
    box-shadow: 0 0 2px 3px #a9790f;
  }
  .li {
    font-size: 11px;
    font-weight: 600;
    padding: 0 1rem;
  }
  .liMenu{
    font-size: 16px;
    font-weight: 600;
    padding: 0 1rem;
  }
  .img {
    max-width: 125px;
  }  
}
@media (max-width: 525px) {  
  .divHome{
    display: none;
  }
  .menu{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .btn{
    margin-right: 1rem;
  }
}