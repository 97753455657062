.section{
    margin: 0;
}
.divPai{
    display: flex;
    position: relative;
    z-index: 1;
    margin: 0;
}
.divPreto {
    width: 60%;
    height: 250px; /* Defina uma altura adequada */
    background-color: black;
    z-index: 1;
  }
  
  .divDourado {
    width: 40%;
    height: 250px; /* Defina uma altura adequada */
    background-color: #A9790F;
    z-index: 1;
  }
.boxText{
    margin: 0;
    z-index: 2;
    position: absolute;
    color: #FFF;
    display: flex;
    align-items: center;
    top: 25%;
    left: 40%;
}
.h3{
    font-size: 48px;
    margin: 0;
    width: 30%;
    line-height: 1;
}
.a{
    color: white;
    text-decoration: none;
    font-size: 36px;
    background-color: #A9790F;
    border: 2px solid black;
    padding: 0.375rem 0.5rem;
    border-radius: 15px;
    cursor: pointer;
    transition: .3s ease-in-out;
}
.a:hover{
    background-color: black;
    border: 2px solid #A9790F;
    padding: 0.375rem 0.5rem;
    border-radius: 15px;
    cursor: pointer;
}

@media (max-width:1440px) {
    .divPreto {
        width: 60%;
        height: 250px; /* Defina uma altura adequada */
        background-color: black;
        z-index: 1;
      }
      
      .divDourado {
        width: 40%;
        height: 250px; /* Defina uma altura adequada */
        background-color: #A9790F;
        z-index: 1;
      }
    .boxText{
        margin: 0;
        z-index: 2;
        position: absolute;
        color: #FFF;
        display: flex;
        align-items: center;
        top: 25%;
        left: 40%;
    }
    .h3{
        font-size: 36px;
        margin: 0;
        width: 30%;
        line-height: 1;
    }
    .a{
        color: white;
        text-decoration: none;
        font-size: 24px;
        background-color: #A9790F;
        border: 2px solid black;
        padding: 0.375rem 0.5rem;
        border-radius: 15px;
        cursor: pointer;
        transition: .3s ease-in-out;
    }
    .a:hover{
        background-color: black;
        border: 2px solid #A9790F;
        padding: 0.375rem 0.5rem;
        border-radius: 15px;
        cursor: pointer;
    }
}

@media (max-width:768px) {
    .divPreto {
        width: 60%;
        height: 200px; /* Defina uma altura adequada */
        background-color: black;
        z-index: 1;
      }
      
      .divDourado {
        width: 40%;
        height: 200px; /* Defina uma altura adequada */
        background-color: #A9790F;
        z-index: 1;
      }
    .boxText{
        margin: 0;
        z-index: 2;
        position: absolute;
        color: #FFF;
        display: flex;
        align-items: center;
        top: 25%;
        left: 30%;
    }
    .h3{
        font-size: 20px;
        margin: 0;
        width: 30%;
        padding-right: 1rem;
        line-height: 1;
    }
    .a{
        color: white;
        text-decoration: none;
        font-size: 14px;
        background-color: #A9790F;
        border: 2px solid black;
        padding: 0.375rem 0.5rem;
        border-radius: 15px;
        cursor: pointer;
        transition: .3s ease-in-out;
    }
    .a:hover{
        background-color: black;
        border: 2px solid #A9790F;
        padding: 0.375rem 0.5rem;
        border-radius: 15px;
        cursor: pointer;
    }
}

@media (max-width:425px) {
    .divPreto {
        width: 60%;
        height: 150px; /* Defina uma altura adequada */
        background-color: black;
        z-index: 1;
      }
      
      .divDourado {
        width: 40%;
        height: 150px; /* Defina uma altura adequada */
        background-color: #A9790F;
        z-index: 1;
      }
    .boxText{
        margin: 0;
        z-index: 2;
        position: absolute;
        color: #FFF;
        display: flex;
        align-items: center;
        top: 25%;
        left: 10%;
    }
    .h3{
        font-size: 18px;
        margin: 0;
        width: 30%;
        padding-right: 2rem;
        line-height: 1;
    }
    .a{
        color: white;
        text-decoration: none;
        font-size: 14px;
        background-color: #A9790F;
        border: 2px solid black;
        padding: 0.375rem 0.5rem;
        border-radius: 15px;
        cursor: pointer;
        transition: .3s ease-in-out;
    }
    .a:hover{
        background-color: black;
        border: 2px solid #A9790F;
        padding: 0.375rem 0.5rem;
        border-radius: 15px;
        cursor: pointer;
    }
}