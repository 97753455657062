.section{
    
}
.div{
    display: block;
    text-align: center;
    padding: 1%;
}
.btn{
    text-decoration: none;
    font-size: 36px;
    font-weight: 600;
    color: black;
}

@media (max-width:1440px) {
    .div{
        display: block;
        text-align: center;
        padding: 1%;
    }
    .btn{
        text-decoration: none;
        font-size: 24px;
        font-weight: 600;
        color: black;
    }
}
@media (max-width:1024px) {
    .div{
        display: block;
        text-align: center;
        padding: 1%;
    }
    .btn{
        text-decoration: none;
        font-size: 18px;
        font-weight: 600;
        color: black;
    }
}
@media (max-width:768px) {
    .div{
        display: block;
        text-align: center;
        padding: 1%;
    }
    .btn{
        text-decoration: none;
        font-size: 14px;
        font-weight: 600;
        color: black;
    }
}